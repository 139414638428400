@font-face {
  font-family: "1903Sans-Regular";
  src: url("/Fonts/1903Sans-Regular.otf");
}

body,
html,
#root,h4 {
  margin: 0;
  font-family: "1903Sans-Regular" !important;
  src: url("/Fonts/1903Sans-Regular.otf");
  font-size: 11px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.3s ease;
  height: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  font-weight: normal !important;
}

span,p, div, label{
  font-family: "1903Sans-Regular" !important;
  font-size: 11px;
  src: url("/Fonts/1903Sans-Regular.otf");
  font-weight: normal !important;
}
label, span{
  font-size: 0.8rem !important
}
input[type='text'] , input[type='number'] { font-family: "1903Sans-Regular" !important;font-size:0.8rem !important }


.padding-0 {
  padding: 0 !important;
}

.navbar-updated {
  width: 95% !important;
  margin: auto !important;
}

.MuiBox-root {
  height: 1500px;
}

.MuiButton-root {
  /* width: 150px;
    text-align: center;
    margin-top: 10px;
    height: 50px; */

  font-size: 1.1875rem;
  text-decoration: none !important;
  color: #fff !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  display: inline-block !important;
  background-color: #fa6600;
  border: 0;
  padding: initial;
  overflow: visible;
}

.MuiButton-textPrimary {
  color: #f50 !important;
}

.MuiButton-label {
  font-family: "1903Sans-Regular";
  font-weight: 700;
  font-stretch: wider;
  line-height: 1rem;
}

.MuiButton-outlinedSecondary>.MuiButton-label {
  color: #f50;
}

.MuiButton-outlined {
  border: 2px solid #f50 !important;
}

img {
  /* max-width: 100%;
  max-height: 100%; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

svg:not(.MuiSvgIcon-root) {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 20px;
}

.MuiCircularProgress-svg {
  width: 40px !important;
  height: 40px !important;
  margin-top: 0 !important;
}

/* .slide-container{
  height: 100%;
}

.slide-container>div,
.react-slideshow-container,
.react-slideshow-fade-wrapper,
.react-slideshow-fade-images-wrap {
  height: 100%;
} */

.slide-container{
  height: 100%;
}

.slide-container>div,
.react-slideshow-container,
.react-slideshow-fade-wrapper,
.react-slideshow-fade-images-wrap {
  height: 100%;
}
div[role="tabpanel"] {
  height: 100%;
}

.MuiStepLabel-label {
  font-weight: bold !important;
}

h1 {
  font-size: 2.0rem;
  line-height: 2.0rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* .MuiGrid-container:not(.MuiGrid-item) {
  height: 100%;
} */

.MuiToolbar-root {
  width: 100%;
}

/* .MuiPickersDatePickerRoot-toolbar {
  align-items: center !important;
  flex-direction: row !important;
} */

.MuiPickersToolbar-toolbar {
  height: 70px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #f50;
}

.MuiTypography-h4 {
  font-size: 1.2rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: left !important;
}

.MuiTypography-subtitle1 {
  font-size: 1.2rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  justify-content: center;
  align-items: center;
  text-align: left !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: rgba(255, 255, 255, 255) !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 0px -0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

/* .formGrid {
  height: 40%;
} */

.otpContainer{
  height: 10% !important;
}

@media only screen and (min-width: 768px) {
  /* .formGrid {
    height: 100%;
  }; */
}

@media (max-width: 480px) {
  .header{
    flex-direction: column
  }
  /* .formGrid {
    height: 58vh !important;
} */

.slide-container{
  height: auto;
}

.slide-container>div,
.react-slideshow-container,
.react-slideshow-fade-wrapper,
.react-slideshow-fade-images-wrap {
  height: auto;
}

.react-slideshow-fadezoom-wrapper{
  height: 100%;
}
}